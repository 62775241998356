.eventNameDiv{
    margin-bottom: 5%;
}

.card-header> h3{
    font-size: 20px;
    font-weight:800 ;
    border-bottom:2px solid #e9eefd;
}
.createevent.new-ticket .ant-form-item-control {
    padding-top: 0;
    padding-left: 0;
}

.createevent.add-ticket .eventNameDiv {
    margin-bottom: 4%;
}

.createevent.add-ticket .ant-col {
    padding: 0;
}
.fieldName{
color:#000;
font-family:Myriad Pro,sans-serif ;
}
.fieldName >span{
    color: red
}
.faq-add .addfaq-form .ant-select .ant-select-selector {
    height: 52px !important;
    border: 1px solid #d9d9d9 !IMPORTANT;
}
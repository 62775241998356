.ant-upload-list-item-actions a{
    display: none!important;
}

button.sbmt-btn {
    background: #235cea;
    color: #fff;
    border: none;
    padding: 8px 30px !important;
    margin-top: 30px;
}

.debities-card {
    margin-bottom: 20px;
}

.personal-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.per-settings {
    align-items: center;
}

.upload-conetent {
    width: 100%;
    margin-left: 10px;
}

.upload-conetent h6 {
    font-size: 14px;
    margin: 0;
}

.personal-box .ant-upload-picture-card-wrapper {
    width: auto;
}
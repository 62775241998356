.upload-picture{
    display: flex;
    padding-left: 0%;
    flex-flow:row wrap;
    justify-content: space-between;
}
span.notification-icon {
    font-size: 12px;
    position: absolute !important;
    top: 5px;
}
.upload-picture .event-upload {
    width: 32%;
}
.notification button.ant-dropdown-trigger {
    border: none !important;
}
.notification button:hover {
    background: #fff3e5 !important;
    color: #f80 !important;
}
.modal-btns {
    padding-top: 25px;
    text-align: right;
}
.header-toprow button.ant-input-search-button span.anticon-search {
    height: 22px;
    width: 22px;
    top: 2px;
    position: relative;
}
.header-toprow button.ant-input-search-button {
    background: transparent !important;
}
.header-toprow button.ant-input-search-button:active {
    border-color: #d9d9d9 !IMPORTANT;
}
.modal-btns .cancel-btn {
    margin-right: 15px;
}
.top-header .right-in {
    display: flex;
    align-items: center;
    margin-bottom: 5%;
}
.container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.container>div{
    width: 100%;
}
form .event-div .ant-select.ant-select-single , .addevent  .ant-select.ant-select-single {
    width: 99% !important;
}
form .addevent {
    width: 98%;
}
.fieldName{
    font-family: Myriad Pro,sans-serif
}
.fieldName >span{
    color:red
}
h2{
    margin-right: 10px;
    margin-bottom: 10px;
    color: #000;
    line-height: normal;
}
.super-admin >p{
    font-size: 14px;
    margin: 0;
}
.action-btn{
    margin-top: 40px;
}

.ant-dropdown {
    width: max-content;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
    background: #235cea !important;
}
.super-admn h3{
    margin-top: 10px;
}
.super-admin p{
    margin-top: -5%;
}
.notification-dropdown{
    padding:15px;
}
.notification-dropdown img{
    width:max-content;
    height: 15px;
}
.notification-length{
    font-size: 20px;
    margin-top: -40%;
}

.top-notifcations {
    max-width: 800px;
    width: 100%;
}

.information-div {
    width: 100%;
}
.ant-dropdown.ant-dropdown-placement-bottomRight {
    top: 64px !important;
}
.info-rigth-div {
    width: 80%;
    word-wrap: break-word;
}
.error.coordinate {
    margin-top: -20px;
    margin-bottom: 17px;
    color: red;
}
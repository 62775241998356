.main-div {
	margin-bottom: 16;
}
.icons-div {
	display: grid;
	width: 140px;
	grid-template-columns: repeat(3, 1fr);
}
.icons-div div {
    height: 40px;
    padding-top: 10px;
    cursor: pointer;
}
.information-div img {
	height: 90px;
	width: 90px;
	margin-right: 15px;
	border-radius: 100%;
	object-fit: cover;
}

.information-div {
	display: flex;
	align-items: center;
}

.information-div p.gray {
	margin: 0;
	color: #707070;
	font-size: 13px;
}

.information-div h6.blue {
	color: #235cea;
	font-size: 15px;
	margin-bottom: 2px;
}

.ant-select-multiple .ant-select-selection-search {
	position: relative;
	width: 100% !important;
	-webkit-margin-start: -4px !important;
	margin-inline-start: -4px !important;
	/* background: red; */
}

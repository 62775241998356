h3 {
	font-weight: 800;
	border-bottom: 2px solid #e9eefd;
	padding-bottom: 10px;
	margin-bottom: 25px; 
}
a, p, body, div, h2, h3, h1, h4, h5, h6, strong, span
{
	font-family: 'Roboto' !important;
}
.avatar-wrapper img.ant-upload-list-item-image {
    object-fit: cover !important;
}
.ant-form p.fieldName {
    font-size: 14px;
    font-weight: 500;
    padding-left: 11px;
}
.ant-col {
    padding: 5px;
}
.confirm-button {
	cursor: pointer;
}
.avatar-wrapper.artist {
    width: 49%;
}
.profile-form p {
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 15px;
    padding-left: 11px;
}
.profile-avatar h2 {
    font-size: 20px;
}
.profile-avatar {
    background: #f2f5fe;
    text-align: center;
    padding: 20px;
    margin: 5px;
	width: 100%;
}
input {
    background: transparent !important;
    color: #000 !important;
    padding: 10px !important;
}
.eye-button {
	height: 24px;
	cursor: pointer;
}
.avatar-wrapper .ant-upload-list-item-list-type-picture-card , .avatar-wrapper .ant-upload-list-picture-card .ant-upload-list-item-info,  .avatar-wrapper .ant-upload-list-item-list-type-picture-card  img {
    border-radius: 100% !important;
}
.icon-button {
	color: #000;
	font-size: 20px;
	margin-right: 10px;
}
.modal-container {
	justify-content: center;
}
.input-name {
	color: red;
}
.business-acccount {
	display: flex;
	margin-left: auto;
	width: 50%;
	margin: auto;
}
.avatar-wrapper {
	cursor: pointer;
    display: flex;
}
.avatar-wrapper > img {
	width: 100%;
}
.fieldName {
	color: #000;
	font-family: Myriad Pro, sans-serif;
}
.fieldNAme > span {
	color: red;
}
.column {
	text-align: center;
	display: flex;
}
.active-btn{
    cursor: pointer;
}
.card-header{
	display: grid;
	grid-template-columns: repeat(4,1fr);
	border-bottom: 2px solid #EBF2F7;
    margin-bottom: 10px;
}

.event-table-manage span.ant-collapse-header-text {
	background: #235CEAE8;
	color: #fff;
	height: 22px !important;
	width: 22px !important;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #fff;
	outline: 2px solid #235CEAE8;
}

.event-table-manage .ant-collapse > .ant-collapse-item > .ant-collapse-header
{
	display: block;
}
.event-table-manage .ant-collapse > .ant-collapse-item {
    border-bottom: none !important;
}

.event-table-manage .ant-collapse-expand-icon {
    display: none;
}
.card-header>h3{
	font-size: 20px;
	font-weight: 800;
	border-bottom: none;
	margin-bottom: 25px;
	border: none !important;
}

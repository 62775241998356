.input-span {
    color:red
}
h2{
    font-size:30px;
    text-align:center
}
.password{
 padding-left: 2px;
 border-radius: 10px;   
 padding-right: 2px;
}
.ant-input-password {
    border-color: #b2b2b2 !important;
    border-radius: 10px !important;
    padding: 0 10px 0 0 !important;
}
.passwordcol input#password {
    border: none !important;
    background: transparent !IMPORTANT;
}
.footer-btns
{
    justify-content: flex-end;
    padding-top: 0 !important;
}

.adduser-form p {
    font-weight: 600;
}
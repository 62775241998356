.event-left-btn button.ant-btn {
	background: transparent !important;
	border: none !important;
	color: red !important;
	box-shadow: none !important;
}
.ant-table-tbody > tr > td {
	vertical-align: text-bottom !important;
}
.event-table-manage .ant-collapse-item.ant-collapse-item-active span.ant-collapse-header-text {
    background: #e9eefd;
    outline-color: #e9eefd;
    color: #000;
}
.event-title {
	font-size: 30px;
	margin-bottom: 5px;
	text-align: left;
}
.bold {
	font-weight: 700;
}
.f-18 {
	font-size: 18px;
}
.event-icon-box img {
	margin-right: 10px;
	height: 18px;
}
.event-start p {
    margin-bottom: 5px;
}
.event-start img {
    border-radius: 100%;
    object-fit: contain;
    height: 35px;
    width: 35px;
}
.viewevent-tables .ant-table-content {
    margin-bottom: 30px;
}
.event-start {
    border-top: 2px solid #7070701f;
    margin-top: 10px;
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: space-around;
    border-bottom: 2px solid #7070701f;
    margin-bottom: 20px;
}
.top-header input.ant-input {
    border-radius: 0 !important;
}

.top-header .ant-input-search {
    margin: 0;
}

button.ant-input-search-button {
    border-radius: 0 !important;
    background: transparent !IMPORTANT;
}
.top-header span.anticon.anticon-search {
    height: 24px;
    width: 24px;
    top: 2px;
    position: relative;
}
.event-icon-box {
	display: flex;
	align-items: flex-start;
}
.ant-form-item {
	margin-bottom: 15px !important;
}
.pick-date-bx {
    align-items: baseline !important;
    margin-right: 10px;
    width: max-content;
}
.pick-date-bx input {
    padding: 7px !important;
}
.border-btn, button.ant-btn.ant-btn-default {
    padding: 10px 30px !important;
    height: auto !important;
    font-size: 16px !important;
	width: max-content;
    border: 1px solid #e4ebfc;
	border-radius: 6px;
}
.user-manage-search span.anticon.anticon-search {
    height: 20px;
    width: 20px;
}
.user-manage-search button.ant-input-search-button {
    height: 46px !important;
    border: 1px solid #d2d2d2;
    background: transparent !important;
}
.user-manage-search input.ant-input {
    border-radius: 0 !important;
}
button.photos-btn,
button.ant-btn.ant-btn-primary {
	background: #235cea;
	color: #fff;
	padding: 10px 30px;
	height: auto;
	font-size: 15px;
	border: 1px solid #235cea;
}
.event-media {
	margin-bottom: 40px;
}
.photos-grid {
	padding-top: 20px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
}
.photos-grid img {
	width: 95%;
	border-radius: 10px;
	object-fit: cover;
}
img.coverimage1 {
    border-radius: 12px;
}
.viewevent-title {
    padding-top: 30px;
}
.event-upload .ant-upload.ant-upload-select,
.event-upload .ant-upload-list-picture-card-container {
	width: 100% !important;
	border-color: #c1c1c1;
	border-radius: 0;
	height: 140px;
}
.modal-btns.edit-event {
	padding-bottom: 40px;
}
.event-upload .ant-upload-list-picture-card-container img {
	object-fit: cover !important;
}
.upload-picture .event-upload img {
	height: 140px;
	object-fit: cover;
}
.event-upload span.ant-upload {
	flex-flow: column;
}
span.pls-icon {
	height: 24px;
	width: 24px;
	color: #235cea;
	border: 2px solid #235cea;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
}

span.cover-txt {
	padding-top: 8px;
}
.upload-grids .event-upload {
	width: 32%;
}

.ant-modal-content .ant-modal-title {
	font-weight: 600;
	color: #000;
	font-size: 22px;
	padding: 5px 0;
}
span.ant-modal-close-x {
	color: #000 !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	height: 43px !important;
}
button.photos-btn:hover {
	background: #fff !important;
	color: #235cea !important;
}
button.cancel-btn:hover {
	background: #235cea !important;
	color: #fff !important;
}
h6.password-title {
    font-size: 22px;
}
button.cancel-btn {
	background: #e4ebfc !important;
	border: none;
	padding: 12px 20px;
	height: auto;
	color: #235cea;
	font-size: 16px;
	font-weight: 500;
}
input,
select,
textarea,
.ant-select-selector {
	border: 1px solid #7070708a;
	border-radius: 6px;
	padding: 10px !important;
}
label {
	font-weight: 600;
}
.createevent {
	margin-top: 32px;
}
.conatiner {
	padding-left: 50px;
	padding-top: 50px;
	padding-right: 50px;
}
.imagge {
	width: 100%;
    height: 150px;
    object-fit: cover;
}
.cancel-btn {
	margin-right: 2%;
}
.box {
	width: 90%;
}
.event-images {
	padding: 10px;
	border-radius: 18px;
}
.editButton {
	padding: 10px;
	border-radius: 18px;
}
.locationDiv {
	display: flex;
	gap: 5px;
}
.mediaButtons {
	display: flex;
	width: 30%;
	gap: 10px;
}
.coverImage {
	margin-top: 10px;
	padding-top: 20px;
	display: grid;
	width: 80%;
	grid-template-columns: repeat(4, 1fr);
	justify-content: space-around;
}
.event-media-btn {
	display: flex;
	gap: 5px;
}
.main-container {
	padding-left: 3%;
	padding-right: 2%;
}
.coverimage1 {
	object-fit: cover;
}
.event-div{
	margin-bottom: 6%;
	margin-top: 1;
	}
	.organizer-div{
		margin-bottom:6%;
		margin-top:4%;
	}
	.eye{
	height: 24px;
	cursor: pointer;
	}
	.genre-box{
	display: flex;
	justify-content:space-between;
	}
	.genre-box >p{
	font-size:30px;
	display: flex;
	flex-direction: column;
	font-weight: bold;
	}
	.genre-box>p>span{
	font-size: small;
	}
	.ant-collapse-content-box table tr  th {
		background: transparent !important;
		padding: 8px;
		font-size: 14px !important;
		width: max-content !important;
	}
	.ant-pagination-options-size-changer.ant-select {
		height: 50px;
		position: absolute;
		top:-78px;
		right: 10;
		width: auto;
		right: 2%;
	}
	.table-action {
		width: max-content !important;
	}
	.element.style {
		table-layout: auto;
		padding-top: 100px;
	}
	.card-header>h3{
		font-size: 20px;
		font-weight: 800;
		border-bottom: none;
		margin-bottom: 25px;
	}
	.deleteIcon{
		color: red;
		margin-left: 5px;
	}

	@media(max-width: 1199px)
	{
		.pick-date-bx {
			max-width: 200px;
		}
		.pick-date-bx input {
			font-size: 12px !important;
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
		.ant-input-search input.ant-input {
			font-size: 12px !important;
			height: 42px;
		}
		.user-manage-search button.ant-input-search-button {
			height: 42px !important;
			padding-left: 14px !important;
			padding-right: 14px !important;
		}
		.header-toprow button.ant-input-search-button {
			background: transparent !important;
			padding: 9px 10px !important;
		}
		
	}
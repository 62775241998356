.event-div{
margin-bottom: 6%;
margin-top: 1;
}
.organizer-div{
    margin-bottom:6%;
    margin-top:4%;
}
.createevent.new-ticket .ant-col.ant-form-item-control {
    padding-top: 0;
    padding-left: 0;
}
.eye{
height: 24px;
cursor: pointer;
}
.genre-box{
display: flex;
justify-content:space-between;
}
.genre-box >p{
font-size:30px;
display: flex;
flex-direction: column;
font-weight: bold;
}
.genre-box>p>span{
font-size: small;
}
.input_field{
    
    width: 420px;
}
.card-header> h3{
    font-size: 20px;
    font-weight:800 ;
    border-bottom:2px solid #e9eefd;
}
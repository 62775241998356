.mt-1 {
	margin-top: 5px;
}

.mt-2 {
	margin-top: 10px;
}

.mb-1 {
	margin-bottom: 5px;
}
.mb-1pr {
	margin-bottom: 1rem;
}

.ml-1pr {
	margin-left: 1rem;
}
.ml-2pr {
	margin-left: 2rem;
}

.mr-1pr {
	margin-right: 1rem;
}

.my-2pr {
	margin-block: 2rem;
}

.my-4pr {
	margin-block: 4rem;
}

.mx-2pr {
	margin-inline: 2rem;
}

.py-2pr {
	padding-block: 2rem;
}

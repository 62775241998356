@use "./functions";
@use "./variables";

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

body {
	margin: 0;
	font-family: variables.$body-font-family;
	font-size: variables.$body-font-size;
	font-weight: variables.$body-font-weight;
	line-height: variables.$body-line-height;
	color: variables.$body-text-color;
	text-align: left;
	background-color: variables.$white;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	overflow-x: hidden;
}

h1,
h2,
h3 {
	font-family: variables.$heading-font-family;
	color: variables.$heading-text-color;
	font-weight: variables.$font-weight-bold;
	margin-bottom: functions.px-Rem(20);
}

a {
	color: variables.$primary;
	text-decoration: none;
}
